<template>
  <navbar>
    <h6 class="title-navbar">
      Kebijakan Privasi
    </h6>
  </navbar>

  <div class="container privacy__wrapper">
    <h6 class="title text-center">
      Kebijakan Privasi
    </h6>
  </div>

  <div class="container privacy__wrapper">
    <p class="date text-center">
      Tanggal pemutakhiran terakhir : 17 December 2020
    </p>
  </div>


  <div class="container privacy__wrapper">
    <div v-if="loading">
      <div class="lazy-loading-content image-container">
      <Skeleton width="100" height="274"/>
      </div>
    </div>
    <div class="content" v-if="!loading && content != null" v-html="content">
    </div>
    <div class="content" v-if="!loading && content == null">
      <ul>
        <li>
          <p>
            <strong>Persetujuan</strong>
          </p>
        </li>
      </ul>
      <p>
        Informasi terlebih lanjut akan Kami jelaskan di bawah ini, bila Anda menggunakan atau sebaliknya mengakses layanan Situs, Kami mungkin mengumpulkan informasi Anda dalam berbagai cara, termasuk ketika Anda memberikan informasi secara langsung kepada Kami, dan ketika Kami secara pasif mengumpulkan informasi dari Situs.
      </p>
      <ul>
        <li>
          <p>
            <strong>Informasi Yang Diminta</strong>
          </p>
        </li>
      </ul>
      <p>
        Ketika Anda hendak menggunakan Situs Kami sebagai user, yaitu donatur terdapat beberapa informasi pribadi yang akan Kami minta seperti nama, alamat email, dan nomor telepon.
      </p>
      <p>
        Apabila Anda mendaftar sebagai penggagas program terdapat beberapa informasi pribadi yang akan Kami minta seperti nama, alamat email, nomor telepon, sosial media, foto diri, KTP, no rekening dan informasi lainnya sesuai dengan keperluan Kami. Kebijakan tersebut Kami minta diperuntukkan keperluan lebih lanjut agar Kami bisa menghubungi Anda.
      </p>
      <p>
        Kami meminta informasi yang diperlukan agar dapat memenuhi permintaan Anda untuk menggunakan Situs. Informasi Anda Kami simpan dan digunakan sesuai dengan ketentuan Undang-Undang, peraturan, kebijakan dan hukum pemerintah yang berlaku. Apabila Anda memilih untuk tidak memberikan informasi apapun, Kami mungkin tidak dapat memenuhi permintaan Anda dalam menggunakan Situs ini, tetapi Anda tetap bisa menggunakan Situs ini sebagai pengguna umum.
      </p>
      <ul>
        <li>
          <p>
            <strong>Penggunaan Informasi</strong>
          </p>
        </li>
      </ul>
      <p>
        Kami menggunakan informasi pribadi Anda untuk berbagai keperluan verifikasi, seperti verifikasi akun, rekening atau identitas lainnya. Hal tersebut diperlukan agar terdapat kejelasan informasi dan tujuan dalam menggunakan Situs Kami.
      </p>
      <p>
        Kami menggunakan alamat email yang Anda berikan untuk mengirimkan pembaharuan atau pesan dari Situs Kami baik secara berkala maupun saat itu juga.
      </p>
      <p>
        Dalam hal tertentu Kami menggunakan informasi Anda untuk meningkatkan mutu dan pelayanan Situs.
      </p>
      <p>
        Kami tidak menjual, menyewakan dan memberikan informasi Anda kepada pihak lain, kecuali apabila ditentukan pada persyaratan lain dan diminta oleh pihak yang berwajib menghendaki untuk meminta informasi kepada Kami.
      </p>
      <ul>
        <li>
          <p>
            <strong>Memperbaiki Informasi Anda</strong>
          </p>
        </li>
      </ul>
      <p>
        Ketika Anda memberikan Kami Data Anda, pastikan memberikan informasi yang akurat dan lengkap. Apabila Anda merasa bahwa terdapat informasi yang Anda berikan kepada Kami mengandung kesalahan atau kelalaian, harap login ke akun Anda di Situs dan perbaiki informasinya.
      </p>
      <p>
        Apabila Anda ingin memperbaiki kesalahan atau kelalaian pada saat pengisian Data manapun di bawah kepemilikan atau kendali Kami yang tidak dapat dikoreksi oleh Anda, harap kirimkan permintaan Anda menggunakan identitas yang telah terdaftar ke kontak Kami yang tertera di bawah ini. Mengenai proses perubahan Data, Kami mungkin akan meminta Data tambahan sesuai dengan permintaan yang Anda ajukan.
      </p>
      <ul>
        <li>
          <p>
            <strong>Keamanan Data</strong>
          </p>
        </li>
      </ul>
      <p>
        Kami tidak pernah memberikan informasi pribadi Anda kepada pihak ketiga, kecuali apabila ketentuan hukum berkehendak lain. Kami menjaga keamanan dan kerahasiaan informasi Anda yang telah masuk dalam database Kami. Untuk mencegah akses yang tidak sah, menjaga keakuratan Data dan memastikan penggunaan informasi yang benar, apabila terdapat informasi yang menurut Anda yang bersifat privasi atau rahasia akan Kami simpan sesuai dengan ketentuan yang disepakati.
      </p>
      <ul>
        <li>
          <p>
            <strong>Pemberitahuan</strong>
          </p>
        </li>
      </ul>
      <p>
        Pengunjung Platform atau Pengguna Platform sepakat dan bersedia untuk menerima segala notifikasi melalui media elektronik, termasuk, namun tidak terbatas pada email, layanan pesan singkat (short messaging service atau SMS) dan/atau pesan instan (instant messaging) yang didaftarkannya pada Platform dan untuk itu memberikan izin kepada Penyedia Platform untuk menghubungi Pengunjung Platform atau Pengguna Platform melalui media elektronik tersebut.
      </p>
      <ul>
        <li>
          <p>
            <strong>Konten Publik</strong>
          </p>
        </li>
      </ul>
      <p>
        Anda mengetahui dan sepakat bahwa setiap informasi yang Anda tampilkan pada konten atau layanan Kami, dapat diakses atau dilihat oleh publik, seperti nama, nomor telepon, email, nominal donasi, komentar pada donasi dan sosial media Anda. Hal tersebut Kami lakukan supaya terdapat keterbukaan informasi bagi pengguna Situs agar tidak dapat menimbulkan kecurigaan ataupun prasangka lainnya yang tidak dapat dipertanggungjawabkan.
      </p>
      <ul>
        <li>
          <p>
            <strong>Cookies</strong>
          </p>
        </li>
      </ul>
      <p>
        Cookies adalah file kecil yang secara otomatis akan mengambil tempat di dalam perangkat Pengguna yang menjalankan fungsi dalam menyimpan preferensi maupun konfigurasi Pengguna selama mengunjungi suatu Situs.
      </p>
      <p>
        Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses Data lain yang Pengguna miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna setujui untuk disampaikan.
      </p>
      <p>
        Walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih untuk menolak cookies (pilihan ini dapat membatasi layanan optimal pada saat melakukan akses ke Situs).
      </p>
      <ul>
        <li>
          <p>
            <strong>Informasi Pribadi dari Anak di Bawah Umur dan Individu Lainnya</strong>
          </p>
        </li>
      </ul>
      <p>
        Sebagai orang tua atau wali yang sah, mohon untuk tidak mengizinkan anak di bawah umur (di bawah 17 (delapan belas) tahun) yang berada dalam pengawasan Anda untuk memberikan Data ke Situs. Apabila Data tersebut diberikan kepada Situs, Anda dengan ini menyetujui pemrosesan Data anak di bawah umur tersebut dan secara pribadi menerima dan setuju untuk terikat oleh Kebijakan ini dan bertanggung jawab atas tindakannya.
      </p>
      <p>
        Dalam keadaan tertentu, Anda mungkin telah memberikan Data yang berkaitan dengan orang lain (seperti pasangan, anggota keluarga atau teman) dan dalam keadaan tersebut, Anda mewakili dan menjamin bahwa Anda diberi wewenang untuk memberikan Data mereka ke Situs dan Anda telah memperoleh persetujuan dari mereka untuk Anda mereka diproses dan digunakan sebagaimana diatur dalam Kebijakan ini.
      </p>
      <ul>
        <li>
          <p>
            <strong>Pengecualian</strong>
          </p>
        </li>
      </ul>
      <p>
        Kami tidak bertanggungjawab atas pemberian, pengungkapan dan pertukaran informasi yang dilakukan oleh Anda sendiri. Kami tidak bertanggungjawab atas segala sesuatu yang mungkin terjadi terhadap setiap informasi mengenai identitas diri yang terdapat pada halaman galang dana.
      </p>
      <ul>
        <li>
          <p>
            <strong>Ketentuan Hukum (legal disclaimer)</strong>
          </p>
        </li>
      </ul>
      <p>
        Kami tunduk terhadap kebijakan perlindungan Data Pengguna sebagaimana yang diatur dalam Peraturan Menteri Komunikasi dan Informatika Nomor 20 Tahun 2016 Tentang Perlindungan Data Pribadi Dalam Sistem Elektronik yang mengatur dan melindungi penggunaan Data dan informasi penting para Pengguna.
      </p>
      <ul>
        <li>
          <p>
            <strong>
              Saran
            </strong>
          </p>
        </li>
      </ul>
      <p>
        Apabila terdapat pertanyaan mengenai Kebijakan ini mohon kirim email pada info@amazingsedekah.com.
      </p>
    </div>
  </div>

  <Menu />
</template>

<script>
import Navbar from '../components/TransactionComponent/NavbarTransaction.vue'
import Menu from '../components/Home/Menu.vue'
import Skeleton from '../components/Skeleton/Skeleton.vue'

export default {
  components: { Navbar, Menu, Skeleton },
  data(){
    return{
      loading: true,
      content: null,
    }
  },
  mounted() {
    this.getContent()
  },
  methods: {
    getContent() {
      this.loading = true;
      const contentSlug = 'privacy'
      this.$http
        .get(`${process.env.VUE_APP_API_URL}contents/${contentSlug}`)
        .then(response => {
          if(response.data && response.data.content){
            this.content = response.data.content;
            this.loading = false;
          }
        })
        .catch(error => {
          this.loading = false;
          //   console.log(error.response)
        })
    }
  }
}
</script>
